<script lang="ts" setup>
const props = defineProps<{
  action: () => Promise<void>
  categoryName: string
}>()

const state = reactive({
  svg: { backgroundImage: '', backgroundRepeat: '', backgroundPosition: '' },
  inProgress: false,

})

const url = new URL(`../../svg/${props.categoryName}.jpg`, import.meta.url).href
state.svg = { backgroundImage: `url(${url})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }
// import(`../../svg/${props.categoryName}.jpg`)
//   // .then(e => state.svg = { backgroundImage: `url(${e.default})` })
//   .then(e => state.svg = { backgroundImage: `url(${url})` })

const handleAction = async () => {
  try {
    state.inProgress = true
    await props.action()
    state.inProgress = false
  }
  catch (e: any) {
    state.inProgress = false
  }
}
</script>

<template>
  <div class="w-full text-center">
    <button :style="state.svg" :disabled="state.inProgress" class="p-6 mb-2 rounded-lg h-180px shadow-lg bg-white dark:text-font w-100% max-w-sm dark:bg-gray-700" @click="handleAction" />
    <slot />
  </div>
</template>

