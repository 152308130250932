<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { ref, watch } from 'vue'
import { useToast } from 'vue-toastification'
import Logo from '~/svg/wordmark-imma-yellow-4c.svg?component'

const router = useRouter()
const { t } = useI18n()

const isOpen = ref(false)
const isLanguageDropDownOpen = ref(false)

const closeWindowOnEscape = (e) => {
  if (e.key === 'Escape')
    isOpen.value = false
}
const toggleMenu = () => {
  isOpen.value = !isOpen.value

  if (isOpen.value) { document.addEventListener('keydown', closeWindowOnEscape) }
  else {
    document.removeEventListener('keydown', closeWindowOnEscape)
    isLanguageDropDownOpen.value = false
  }
}

const toggleLanguageDropdown = () => {
  isLanguageDropDownOpen.value = !isLanguageDropDownOpen.value
}

const store = useUserStore()

const { user } = storeToRefs(store)

const logout = () => {
  store.logout()
}

const goToHome = () => {
  router.push('/')
  isOpen.value = false
}

const goToProjectOverview = () => {
  router.push('/project-overview')
  isOpen.value = false
}

const goToMediaLibrary = async () => {
  await router.push('/media-library')
  isOpen.value = false
}

const goToHelp = () => {
  router.push('/help')
  isOpen.value = false
}
const goToForBusinesses = () => {
  router.push('/media-project-draft/business')
  isOpen.value = false
}

const goToAvailableMediaProjects = () => {
  router.push('/backoffice/available-media-projects')
  isOpen.value = false
}

const goToCustomerMediaProjects = () => {
  router.push('/backoffice/media-projects-created-by-customers')
  isOpen.value = false
}

const goToResetOfflineCard = () => {
  router.push('/backoffice/reset-offline-card')
  isOpen.value = false
}

const changeLanguage = async (languageCode: string) => {
  await store.changeLanguage(languageCode)
  const toast = useToast()
  toast.success(t('settings.language-saved'))
}

const dark = useDark()

const toggleDark = () => {
  dark.value = !dark.value
}
watch(
  () => isOpen.value,
  () => {
    if (isOpen.value)
      document.body.style.setProperty('overflow', 'hidden')
    else document.body.style.removeProperty('overflow')
  },
  {
    immediate: true,
  },
)

onUnmounted(() => {
  document.removeEventListener('keydown', closeWindowOnEscape)
})
</script>

<template>
  <nav
    class="
      flex
      fixed
      w-full
      items-center
      justify-between
      px-6
      h-16
      bg-primary
      border-b
      z-10
    "
  >
    <div class="flex items-center text-secondary">
      <button class="" aria-label="Open Menu" @click="toggleMenu" @keydown.esc="toggleMenu">
        <svg
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          class="w-8 h-8"
        >
          <path d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>
      <button @click.stop="goToHome">
        <Logo class="fill-secondary min-h-16" />
      </button>
    </div>
    <div>
      <button v-if="store.isLoggedIn" class="btn bg-secondary text-font flex px-4 py-2 text-sm" @click="logout">
        {{ t('global.logout') }}
      </button>
    </div>

    <transition
      enter-from-class="opacity-0"
      enter-active-class="ease-out transition-medium"
      enter-to-class="opacity-100"
      leave-from-class="opacity-100"
      leave-active-class="ease-out transition-medium"
      leave-to-class="opacity-0"
    >
      <div v-show="isOpen" class="z-10 fixed inset-0 transition-opacity" @keydown.esc="toggleMenu">
        <div class="absolute inset-0 bg-black opacity-50" tabindex="0" @click="toggleMenu" />
      </div>
    </transition>
    <aside
      class="
        transform
        bg-background
        dark:bg-black
        top-0
        left-0
        w-64
        fixed
        h-full
        overflow-auto
        ease-in-out
        transition-all
        duration-300
        z-30
      "
      :class="isOpen ? 'translate-x-0' : '-translate-x-full'"
    >
      <span class="flex w-full items-center bg-primary p-4 border-b mb-4" @click="toggleMenu">
        <Logo class="h-auto w-32 mx-auto fill-secondary" />
      </span>
      <span class="aside-menu-item" @click="goToHome">
        <i
          class="w-32px h-32px  i-carbon:home mr-4"
        />
        <span>{{ t("global.home") }}</span>
      </span>
      <span class="aside-menu-item" @click="goToProjectOverview">
        <i
          class="w-32px h-32px  i-carbon:play-outline mr-4"
        />
        <span>{{ t("navbar.project-overview") }}</span>
      </span>
      <span class="aside-menu-item" @click="goToMediaLibrary">
        <i
          class="w-32px h-32px  i-carbon:media-library mr-4"
        />
        <span>{{ t("navbar.media-library") }}</span>
      </span>

      <span class="aside-menu-item" @click="goToHelp">
        <i
          class="w-32px h-32px  i-carbon:help mr-4"
        />
        <span>{{ t("navbar.help") }}</span>
      </span>

      <hr class="divider bg-gray mt-4 mb-4">

      <span class="aside-menu-item bg-secondary dark:text-font font-bold hover:bg-secondary-500" @click="goToForBusinesses">
        <i
          class="w-32px h-32px  i-carbon:industry mr-4"
        />
        <span>{{ t("navbar.for-businesses") }}</span>
      </span>

      <div v-if="user.hasRole('backoffice')">
        <hr class="divider bg-gray mt-4 mb-8">
        <div class="font-bold ml-2 mb-4">
          Admin-Bereich
        </div>
        <div class="flex items-center">
          <span class="aside-menu-item" @click="goToAvailableMediaProjects">
            <i
              class="w-32px h-32px  i-carbon:video-add mr-4"
            />
            <span>Verfügbare Videobotschaften</span>
          </span>
        </div>
        <div class="flex items-center mb-8">
          <span class="aside-menu-item" @click="goToCustomerMediaProjects">
            <i
              class="w-32px h-32px  i-carbon:dashboard mr-4"
            />
            <span>Kunden Video-Übersicht</span>
          </span>
        </div>
        <div class="flex items-center mb-8">
          <span class="aside-menu-item" @click="goToResetOfflineCard">
            <i
              class="w-32px h-32px  i-carbon:reset mr-4"
            />
            <span>Offline-Karte resetten</span>
          </span>
        </div>
      </div>
      <hr class="divider bg-gray mt-4 mb-8">
      <div class="font-bold ml-2 mb-4">
        {{ t('global.settings') }}
      </div>
      <div class="flex items-center mb-8">
        <span class="ml-4 mr-4">{{ t('global.language') }}: </span>
        <div class="relative inline-block text-left">
          <button
            id="menu-button" type="button" class="flex items-center w-full gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" aria-expanded="true" aria-haspopup="true"
            @click="toggleLanguageDropdown"
          >
            <span class="fi fib" :class="`fi-${user.language === 'en' ? 'us' : 'de'}`" />

            <span>{{ user.language === "en" ? 'English' : 'Deutsch' }}</span>
            <svg class="-mr-1 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
            </svg>
          </button>

          <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <div v-show="isLanguageDropDownOpen" class="absolute left-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
              <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
              <div>
                <a id="menu-item-0" :class="'de' === user.language ? 'font-semibold bg-gray-100' : ''" class="language-item" role="menuitem" tabindex="-1" @click.prevent="changeLanguage('de')"><span class="fi fib fi-de mr-4" /> Deutsch</a>
                <a id="menu-item-1" :class="'en' === user.language ? 'font-semibold bg-gray-100' : ''" class="language-item" role="menuitem" tabindex="-1" @click.prevent="changeLanguage('en')"><span class="fi fib fi-us mr-4" /> English</a>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div class="mb-12 ml-4">
        <label for="toggle" class="flex items-center cursor-pointer relative mb-4">
          <input id="toggle" type="checkbox" class="sr-only" :checked="dark" @change="toggleDark">
          <div class="toggle-bg bg-gray-300 border-2 border-gray-300 h-6 w-11 rounded-full" />
          <span class="ml-4">{{ t('global.dark-mode') }}</span>
        </label>
      </div>

      <hr class="divider bg-gray mt-4 mb-8">
      <div class="flex flex-col ml-4 space-y-8">
        <a href="https://www.imma.video/impressum" target="imprint">{{ t('global.imprint') }}</a>
        <a href="https://www.imma.video/wp-content/uploads/2024/09/agb.pdf" target="terms-of-service">{{ t('global.terms-of-service') }}</a>
        <a href="https://www.imma.video/wp-content/uploads/2024/09/datenschutzbestimmungen.pdf" target="privacy-policy">{{ t('global.privacy-policy') }}</a>
      </div>
      <div class="pt-10" />
    </aside>
  </nav>
</template>

<style lang="postcss">
.language-item {
  @apply block;
  @apply px-4;
  @apply py-2;
  @apply hover:bg-gray-100;
  @apply rounded-md;
  @apply hover:cursor-pointer;
  @apply dark:text-font
}

.toggle-bg:after {
    content: '';
    @apply absolute top-0.5 left-0.5 bg-white border border-gray-300 rounded-full h-5 w-5 transition shadow-sm;
    @apply dark:bg-dark;
}
input:checked + .toggle-bg:after {
    transform: translateX(100%);
    @apply border-white;
    @apply dark:border-dark;
}
input:checked + .toggle-bg {
    @apply bg-blue-600 border-blue-600;
    @apply dark:bg-white border-white
}
</style>
