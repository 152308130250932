Enter Time: Zeit eingeben
PassedAway: Gestorben
WillRegistered: Einwilligungserklärung registriert
accept-stripe-cookies: >-
  Wir leiten dich auf unsere Bezahlseite, die von Stripe zur Verfügung gestellt
  wird, weiter. Diese Seite speichert Cookies, die unter anderem zur
  Betrugsprävention dienen. Bevor du dein Video hochladen kannst, müssen wir
  deine Erlaubnis dafür einholen.<br/> Nähere Infos zu den Cookies findest du
  unter <a href="https://stripe.com/de/legal/cookies-policy" class="link"
  target="stripe_cookies">Cookie Policy</a>.
address: Adresse
amount:
  hint-text: Geben Sie die Anzahl der Karten ein, die Sie bestellen möchten.
assets: Vermögen
assets-information:
  abos:
    hint-text: >-
      Abos und Verträge bleiben aktiv, bis sie gekündigt werden. Teile deinen
      Erben mit, was sie schnellstmöglich kündigen sollten und erspare ihnen
      bares Geld. Z.B. Netflix, RTL-Plus, Unfallversicherung, 
      Berufsunfähigkeitsversicherung bei …, ORF-Haushaltsabgabe, ...<br/>  Prüfe
      auch <a
      href="https://www.oesterreich.gv.at/themen/gesundheit_und_notfaelle/todesfall/Seite.190900.html"
      class="link" target="abo_list">diese Liste</a>.
    label: 'Abos und Verträge:'
  assetsHardToFind: 'Wertsachen, die man sonst nicht findet:'
  credentials:
    hint-text: >-
      Amazon Benutzername/Email + Passwort, Passwort für Handynetzbetreiber
      (meistens 4-stellig), ...
    label: 'Zugangsdaten für Online-Anbieter:'
  heading: Informationen über Vermögen und Abos (optional)
  hiddenAssets:
    hint-text: >-
      Verstecke von Geld, Gold, Bitcoin-Hardware-Wallets, Zugangsdaten für
      Online-Banking und Online-Wallets, ...
    placeholder: >-
      In meinem Bücherregal zwischen Harry Potter - Stein der Weisen und Harry
      Potter - Der Gefangene von Askaban bewahre ich die Zugangsdaten zu meinem
      Tresor auf.
  info: >-
    Teile deinen Empfängern deine aktuelle Vermögensaufstellung mit und wie sie
    Zugriff zu diesem bekommen. Erspare deinen Erben bares Geld, indem du sie
    auch über aktuelle Verträge und Abos informierst, die sie im Falle deines
    Ablebens schnellstmöglich kündigen sollten.
  whereToGetMoney:
    hint-text: >-
      Lebensversicherung Uniqa (Polizze Nr …), Sterbeversicherung Wiener Verein,
      ...
    label: 'Dort bekommt mein Erbe noch Geld:'
    placeholder: >-
      {'Kontaktiere Uniqa unter heinz.leitner@uniqa.at mit meiner
      Versicherungsnummer 22334983839 und informiere ihn über mein Ableben.'}
author-management:
  title: Autorenstatus
authorStatus:
  registration-done-info: Die Registrierung wurde erfolgreich abgeschlossen! &#x1F973;
  signature-verified: Die Signatur wurde erfolgreich als verifiziert markiert.
button:
  about: Über
  back: Zurück
  home: Startseite
  toggle_dark: Dunkelmodus umschalten
  toggle_langs: Sprache ändern
city: Stadt
components:
  select-one: Bitte wählen
country: Land - Versandzeit
create:
  media:
    project: Videobotschaft erstellen
customer-management:
  passed-away: Verstorben
  title: Kundenmanagement
  will-registered: Testament registriert
date-of-birth-saved: Dein Geburtsdatum wurde erfolgreich gespeichert.
e-mail-address: E-Mail-Addresse
enter-time:
  info: >-
    Wähle das Datum aus, an dem der/die Empfänger:in deine Nachricht erhalten
    soll. 

    Gib das gewünschte Datum in deiner Zeitzone ein und wähle die Zeitzone des
    Empfängers aus. 

    Unser System berücksichtigt die Zeitzone des Empfängers, um die Nachricht
    rechtzeitig zu senden.
  when-should-video-be-delivered: Wann soll das Video beim Empfänger eintreffen?
errors:
  business: |-
    Es gab leider Validierungsfehler. 
    Bitte überprüfe die Fehlerdetails unten.

    {errors}
  dateInvalid: Das Datum ist unvollständig oder existiert nicht.
  fileTooLargeOrNotPdf: Die Datei muss vom Typ .pdf sein und eine Größe von weniger als 10 MB haben.
  form-validation-failed: Es gab leider Validierungsfehler. Bitte überprüfe deine Eingaben.
  get-individual-offer: >-
    Bitte kontaktieren Sie uns ab 50 Karten unter <a
    href="mailto:vertrieb&#x0040;imma.video"
    class="link">vertrieb&#x0040;imma.video</a> und erhalten Sie Ihr
    individuelles Angebot.
  maxDate: Das Datum darf nicht mehr als ein Jahr in der Zukunft liegen.
  minDate: Das Datum muss in der Zukunft liegen.
  minDateLetter: >-
    Das Datum muss mindestens {days} Tage in der Zukunft liegen, um den Brief
    vorzubereiten und die Zustellung zum gewünschten Datum zu gewährleisten.
  oops: >-
    Es tut uns leid – etwas ist schief gelaufen! 

    Wir haben den Fehlerbericht erhalten und arbeiten bereits daran, das Problem
    zu beheben! Du kannst in der Zwischenzeit versuchen, die Seite neu zu laden.
  save-date-of-birth: >-
    Leider ist beim Speichern des Geburtsdatums ein Fehler aufgetreten. Bitte
    versuche es noch einmal oder wende dich an unseren <a
    href="mailto:support&#x0040;imma.video" class="link">Support</a>.
  unauthorized: |-
    Dein Login ist abgelaufen. 
     Bitte melde dich noch einmal an.
  unsupportedMimeType: |-
    Das Video ist vom Typ „{0}“. 
    Bitte versuche es mit einem anderen Browser oder wende dich an den Support.
general:
  from-beyond: Ableben
  joyful-occasion: Freudiger Anlass
  start-project: Videobotschaft erstellen
global:
  add: Hinzufügen
  address: Adresse
  amount: Anzahl
  back-to-project-overview: Zurück zu den Videobotschaften
  cancel: Abbrechen
  continue: Vervollständigen
  convert: Konvertieren
  create: Erstellen
  dark-mode: Dark Mode
  delete: Löschen
  delete-now: Jetzt löschen
  drafts: Begonnene Videobotschaften
  edit-assets: Vermögen & Abos bearbeiten
  edit-recipient: Empfänger bearbeiten
  edit-time: Zeit bearbeiten
  edit-video: Video ansehen/bearbeiten
  filename: Dateiname
  first-name: Vorname
  go-to-home: Zur Startseite gehen
  home: Startseite
  imprint: Impressum
  language: Sprache
  last-name: Nachname
  logout: Abmelden
  more-info: Über imma
  next: Weiter
  pay-now: Zur Kasse
  preferred-contact-times: Gewünschte Rückrufzeiten
  privacy-policy: Datenschutzerklärung
  project-name: Projekttitel
  project-name-hint-text: >-
    Benenne deine Videobotschaft, damit du den Überblick bewahrst. <br/>Dieser
    Titel ist nur für dich ersichtlich.
  projects: Veröffentlichte Videobotschaften
  publish-now: Jetzt aktivieren
  recently-started: Weitermachen
  register-now: Jetzt anmelden
  remove: Entfernen
  rename: Umbenennen
  save: Speichern
  search: Suchen
  settings: Einstellungen
  short-id: ID der Videobotschaft (letzter Teil der URL)
  sign-now: Jetzt unterschreiben
  status: Status
  storage: Speicher
  terms-of-service: AGB
  upload: Hochladen
  upload-and-publish: Hochladen
  used: verbraucht
  video-deleted: Die Videonachricht wurde erfolgreich gelöscht.
  watch-video: Video ansehen
  your-first-name: Dein Vorname
go-back: Zurück
help:
  callback-error: >-
    Die Anforderung des Rückrufs war nicht erfolgreich. Bitte sende die
    Rückrufanfrage erneut, indem du noch einmal auf den Button klickst.
  callback-requested: >-
    Die Anforderung des Rückrufs war erfolgreich. Wir kontaktieren dich so
    schnell wie möglich.
  desired-callback-times-hint: Teile uns mit, wann du am liebsten kontaktiert werden möchtest.
  request-callback: Rückruf anfordern
intro:
  from-beyond-info: >-
    Hinterlasse für den traurigen Fall deines Ablebens eine ganz einzigartige
    Videobotschaft für deine Lieben. Durch die automatisierten Prozesse im
    Hintergrund, weiß imma über dein Ableben Bescheid und versendet die
    Videobotschaften in deinem Namen automatisch per E-Mail oder Brief und
    stellt sicher, dass sie bei den Empfängern ankommen. Niemand muss also aktiv
    werden, um deine Videobotschaften abrufen zu können. 
  hi: Hi, {name}!
  joyful-occasion-info: >-
    Schenke doch einmal etwas anderes! Etwas Persönliches. Etwas für die
    Ewigkeit. Etwas, woran sich deine Lieben noch lange erinnern werden!

    imma bietet die Möglichkeit eine Videobotschaft aufzunehmen und zu einer
    Geburt, einer Hochzeit, zu Weihnachten oder zu einem anderen freudigen
    Anlass zu versenden. Nimm jetzt deine Videobotschaft auf und gib das
    gewünschte Datum ein, an dem der Empfänger die Nachricht erhalten soll.
language: Sprache
media-library:
  add-now: Jetzt hinzufügen
  add-to-media-library: Zur Mediathek hinzufügen
  confirm-and-go-to-pin: Bestätigen und weiter zur PIN-Eingabe
  go-to-media-library: Zur Mediathek
  no-entries: Du hast noch keine Videobotschaften zu deiner Mediathek hinzugefügt.
  successfully-added: Die Videobotschaft wurde erfolgreich zu deiner Mediathek hinzugefügt.
  successfully-removed: Die Videobotschaft wurde aus deiner Mediathek entfernt.
media-project:
  add-to-media-library: Jetzt zur Mediathek hinzufügen
  clear-pin: PIN löschen
  enter-your-pin-code: Gib deinen PIN-Code ein
  invalid-pin-or-not-found: Das Video wurde nicht gefunden oder die eingegebene PIN ist ungültig.
  presentation-video: >-
    <p class="mb-4">Schön, dass du da bist! Entdecke in diesem Video, wie unsere
    Videokarten funktionieren! Viel Freude!</p> <p>Mehr Infos auf <a
    target="homepage" href="https://imma.video"
    class="link">www.imma.video</a></p>
  presentation-video-business: >-
    <p class="mb-4">Schön, dass Sie hier sind! Entdecken Sie in diesem Video,
    wie unsere Videokarten zum Leben erwachen! Viel Freude beim Schenken!</p>
    <p>Mehr Infos auf <a target="homepage" href="https://imma.video"
    class="link">www.imma.video</a></p><p>Anfragen bitte an <a
    href="mailto:vertrieb&#x0040;imma.video"
    class="link">vertrieb&#x0040;imma.video</a></p>
  public-watch-first-view-info: >-
    Du hast die PIN erfolgreich eingegeben und hast nun Zugriff zur Nachricht
    von {name}. Wir wünschen viel Vergnügen!
  public-watch-joyful-occasion-deleted-info: >-
    Diese Videobotschaft wurde von {name} bereits gelöscht. Damit du den Zugriff
    nicht verlierst, kannst du sie noch bis <b>{accessUntil}</b> zu deiner
    persönlichen Mediathek hinzufügen. Falls du die Nachricht nicht innerhalb
    dieses Zeitraumes hinzugefügt hast, löschen wir sie unwiderruflich von
    unseren Servern.
  public-watch-joyful-occasion-info: >-
    Füge die Nachricht am besten jetzt gleich zu deiner persönlichen Mediathek
    hinzu, damit du den Zugang zu dem Video nicht verlierst, falls {name} die
    Nachricht löscht. Es geht ganz schnell - versprochen!
  public-watch-media-library-info: >-
    <p class="paragraph">Die Videobotschaft steht dir für 1 Jahr ab dem ersten
    Zugriff zur Verfügung. Der Inhalt ist also noch <b>bis {accessUntil}</b>
    verfügbar.</p> <p class="paragraph"> Wenn du <b>unbegrenzten</b> Zugriff auf
    {name}'s Nachricht möchtest, dann füge sie deiner persönlichen Mediathek
    hinzu. </p>
  status:
    active: Aktiv
    complete-draft: Entwurf vervollständigen
    consentRegistrationRequired: Einwilligungserklärung ausstehend
    converting: Konvertieren
    is-draft: Entwurf
    not-payed: Nicht bezahlt
    payment-failed: Bezahlung fehlgeschlagen
    payment-in-progress: Bezahlung gestartet
    sent: Gesendet
    signature-verification: Signaturprüfung
    space-required: Speicherplatz benötigt
  try-out-now: >-
    Probier es doch gleich aus und sorge für unvergessliche Momente - mit etwas,
    das eure tiefe Verbundenheit auf wundervolle Weise zum Ausdruck bringt!
  try-out-now-business: >-
    Worauf noch warten? Stärken Sie Ihre Präsenz und fördern Sie die Bindung
    Ihrer Mitarbeiter:innen und Kund:innen an Ihr Unternehmen!
must-be-older-than-18: Um diesen Service nutzen zu können, musst du älter als 18 Jahre sein.
my-projects: {}
navbar:
  draft-overview: Begonnene Videobotschaften
  for-businesses: Für Unternehmen
  help: Hilfe
  media-library: Mediathek
  project-overview: Videobotschaften
next-steps:
  after-death-first-video:
    step1: >-
      Unterschreibe die Einwilligungserklärung digital mit ID Austria (vormals
      Handysignatur) und lade sie hoch. Dein Video wird in der Zwischenzeit im
      Hintergrund für verschiedene Bandbreiten und Geräte optimiert, um das
      bestmögliche Benutzererlebnis zu bieten.
    step2: >-
      Nach erfolgreicher Verifizierung ist deine Videobotschaft aktiv.

      Du kannst die Videobotschaft jederzeit ändern oder weitere
      Videobotschaften verfassen.

      Vielen Dank für dein Vertrauen!
  heading: Nächste Schritte
  joyful-occasion:
    nothing-to-do-for-you-info: >-
      Nichts für dich zu tun! &#x1F60E; Warte einfach entspannt darauf, dass die
      Videobotschaft jemanden richtig glücklich machen wird! &#x1F60D; Vielen
      Dank für dein Vertrauen!
  optimize-video-info: >-
    Das Video wird im Hintergrund für verschiedene Bandbreiten und Geräte
    optimiert, um den Empfängern das bestmögliche Benutzererlebnis zu bieten.
  thank-you: Vielen Dank für das Hochladen deiner Videobotschaft!
  will-already-registered: >-
    Nichts für dich zu tun! Alle Prozesse für den automatischen Videoversand
    sind bereits eingerichtet! Diese Videobotschaft wird nach erfolgreicher
    Zahlung sofort aktiv. Vielen Dank für dein Vertrauen!
not-found: |-
  Diese Seite konnte nicht gefunden werden. 
  Bitte gehe zurück oder zur Startseite.
payment:
  already-done: >-
    Die Bezahlung wurde bereits erfolgreich durchgeführt. Gehe zurück auf die
    Projektübersicht, um den Status zu überprüfen.
  cancelled-info: >-
    Deine Zahlung wurde abgebrochen. Um die Videobotschaft zu aktivieren, starte
    den Bezahlvorgang bitte erneut, indem du auf den Button "Zur Kasse" klickst.
  error: Deine Zahlung war nicht erfolgreich. Bitte versuche es erneut.
  in-progress: >-
    Deine Zahlung wird derzeit ausgeführt. Du wirst über den Zahlungsstatus
    informiert, wenn der Bezahlvorgang abgeschlossen wurde.
  pay-now: Zur Kasse
  requires-action: >-
    Du hast die Bezahlung nicht abgeschlossen. Möglicherweise hast du den
    Bezahlvorgang abgebrochen oder es ist ein anderer Fehler aufgetreten. Bitte
    erneut versuchen.
  requires-payment-method: Deine Zahlung war nicht erfolgreich. Bitte versuche es erneut.
  status: {}
phone: Telefonnummer
pricing:
  first-after-death-video-info: >-
    Für die erstmalige Einrichtung berechnen wir 95€. 

    Diese Gebühr ist einmalig und deckt die Kosten für die Einrichtung der
    automatischen Prozesse.

    Danach verrechnen wir nur den tatsächlich verbrauchten Speicherplatz (5€ /
    5GB und Jahr).
  free-tier-active: Das kostenlose Kontingent ist aktiv.
  from: ab
  storage-info: >-
    Für das erste Video bis zu 200 MB entstehen keine Speicherplatzkosten.
    Zusätzlich benötigter Speicherplatz wird in 5GB-Schritten mit einer
    Jahresgebühr von jeweils 5€ verrechnet.
  storage-seats-used: 'Genutzte Speicherplatzeinheiten im aktuellen Abonnementzeitraum:'
project-overview:
  assets-updated: Informationen zu Vermögen und Abos erfolgreich gespeichert.
  no-drafts: Hier hast du im Moment keine begonnenen Videobotschaften.
  no-projects: Hier hast du im Moment keine Videobotschaften.
  recipient-updated: Empfänger erfolgreich aktualisiert.
  rename-successful: Videobotschaft erfolgreich umbenannt.
  time-updated: Ankunftszeit erfolgreich aktualisiert.
  video-uploaded: Video erfolgreich aktualisiert.
receiver: Empfänger
recipient:
  delivery-country-text: '{name} – 1 Tag | {name} – {n} Tage'
  hint-language: >-
    Wähle die Sprache des Video-Empfängers. Sämtliche Kommunikation an die
    Empfänger berücksichtigt die hier ausgewählte Sprache.
  hint-language-offline-sale: >-
    Die Sprache der gekauften Karte ist vorausgewählt und kann nicht geändert
    werden. Sämtliche Kommunikation an die Empfänger berücksichtigt die hier
    ausgewählte Sprache.
  hint-phone-number: >-
    Eine Telefonnummer hilft uns, mit dem Empfänger in Kontakt zu treten, falls
    es zu Problemen mit der Lieferung kommen sollte.
  make-video-more-personal: Mach deine Nachricht noch persönlicher!
  make-video-more-personal-info: >-
    Lass uns deine Videobotschaft per personalisiertem Brief zusenden. Wir
    drucken einen QR- und PIN-Code für den Zugriff auf die Videobotschaft auf
    den Brief und sorgen dafür, dass der Brief zum gewünschten Termin beim
    Empfänger ankommt.
  message-will-be-send-via-letter: >-
    Wir versenden deine Videobotschaft per Brief an die unten angegebenen
    Empfängerdaten.
  open-preview: Vorschau öffnen
  send-via-letter: Per Brief verschicken
save-and-continue-later: Speichern und später fortfahren
scheduled-send: Versandzeitpunkt
select-a-type-first: >-
  Bitte wähle aus, ob du die Videobotschaft aus dem Jenseits oder für einen
  freudigen Anlass verwenden möchtest.
send-type:
  as-mail: >-
    imma versendet deine Videonachricht automatisiert per E-Mail zum gewünschten
    Versandzeitpunkt.
  let-imma-send: >-
    imma kümmert sich um alles und schickt die mit dem Motiv bedruckte Grußkarte
    inkl. Anleitung zum Öffnen des Videos zum von dir gewünschten Ankunftsdatum
    an den/die Empfänger:in. Unser System verschickt dabei die Grußkarten so,
    dass sie mit höchster Wahrscheinlichkeit genau zum gewünschten Ankunftsdatum
    oder ein bisschen früher bei den Empfängern ankommen.
  self: >-
    Wir schicken dir eine mit dem Motiv bedruckte Grußkarte inkl. Anleitung zum
    Öffnen des Videos. Du kannst auf die Rückseite ein paar persönliche Worte
    schreiben und sie einem Geschenk beilegen.
sent-video:
  deletion-error: >-
    Beim Versuch, das Video zu löschen, ist ein Fehler aufgetreten. 

    Bitte versuche es erneut, indem du auf die Schaltfläche „Jetzt löschen“
    klickst. 

    Wenn das Problem weiterhin besteht, <a href="/help#contact"
    class="link">kontaktiere uns</a> bitte.
  deletion-heading: Gesendetes Video löschen
  deletion-info: >-
    <p class="paragraph">Wenn die Empfänger deine Videonachricht noch nicht zu
    ihrer persönlichen Mediathek hinzugefügt haben, informieren wir die
    Empfänger per E-Mail über die Löschung. Die Empfänger haben dann noch
    <b>einen Monat Zeit</b>, deine Videobotschaft zu ihrer Mediathek
    hinzuzufügen, bis das Video von unseren Servern gelöscht wird. </p> <p
    class="paragraph">Der Speicherplatz wird in deinem Konto aber sofort frei,
    nachdem du auf die Schaltfläche „Jetzt löschen“ geklickt hast. Du hast
    danach <b>keinen Zugriff</b> mehr auf das Video!</p>
server-validation:
  dateMustBeInTheFuture: Das gewählte Datum muss in der Zukunft liegen.
settings:
  language-saved: Sprache gespeichert.
short-id:
  hint-text: >-
    Wenn die URL (zu finden entweder auf der Rückseite deiner Karte oder im
    Browser) https://app.imma.video/p/ABCdef lautet, dann ist die ID der
    Videobotschaft ABCdef.
sign-consent:
  download-signed-constent: Einwilligungserklärung herunterladen
  heading: Einwilligungserklärung herunterladen, signieren und hochladen
  info: >-
    Zur Freischaltung der Videobotschaften nach deinem Ableben benötigen wir
    deine digital signierte Einwilligung. 

    Dies ist ein einmaliger Einrichtungsvorgang und weitere Videos werden direkt
    aktiviert.  Videobotschaften für freudige Anlässe benötigen diese
    Einwilligung nicht und sind nach dem Hochladen sofort aktiv.  <br/><br/>
    Bitte lade die Einwilligungserklärung herunter und verwende zum Signieren
    eine Signaturplattform wie <a class="link"
    href="https://www.prime-sign.com/primesign/"
    target="primesign">PrimeSign</a>. 

    Für diesen Vorgang benötigst du <a class="link"
    href="https://www.oesterreich.gv.at/id-austria.html" target="_idaustria">ID
    Austria</a>.
  info-upload: 'Danach lade es hier wieder hoch und deine Videobotschaften werden aktiv. '
  verifying: >-
    Wir haben deine Einwilligungserklärung erhalten und prüfen nun die Signatur.
    Wir senden dir eine Bestätigung per E-Mail, wenn wir die Signatur
    erfolgreich prüfen konnten und deine Videobotschaften werden aktiv.
successful-saved: Daten gespeichert.
time: Ankunftsdatum
timezone: Zeitzone
upload:
  change-video: Anderes Video aussuchen
  connect-to-account: Mit Account verknüpfen
  connect-to-account-info: >-
    Das Video kann ab jetzt nur mehr über eine Verknüpfung mit deinem Account
    geändert werden. Wenn du es jetzt zu deinem Account hinzufügen möchtest,
    klicke auf
  conversion-error: >-
    Es tut uns sehr leid! 

    Die Videokonvertierung ist leider fehlgeschlagen. 

    Wir haben den Fehlerbericht bereits erhalten und untersuchen nun das
    Problem. 

    In der Zwischenzeit kannst du den Upload mit einem anderen Browser erneut
    versuchen. 

    Sollte es immer noch nicht funktionieren, wende dich bitte an unseren
    Support.
  conversion-success: >-
    Das Video wurde erfolgreich für verschiedene Bandbreiten und Endgeräte
    optimiert. Du kannst die Videonachricht noch einmal kontrollieren und
    ändern. Wenn du fertig bist - mit dem Button "Aktivieren" die Karte
    freischalten.
  conversionInfo: >-
    Das Video wird in das Design der gewählten Videokategorie konvertiert. 

    Nach der Konvertierung kannst du das Video überprüfen. Für diesen Schritt
    ist keine Zahlung erforderlich.
  conversionInfoOfflineSale: >-
    Das Video wird in das Design der Kartenkategorie konvertiert. 

    Nach der Konvertierung kannst du das Video überprüfen und ggf. ändern, bis
    es für dich passt.
  freetier-info:
    heading: Kostenloses Speicherkontingent aktiv
    message: >-
      Glückwunsch! 

      Dieses Video ist im kostenlosen Speicherkontingent inkludiert und es
      fallen keine Kosten für den Speicherplatz an!
  freetier-storage-exceeds-info:
    heading: Überschreiten des kostenlosen Speicherkontingents
    message: >-
      Dieses Video ist nicht im kostenlosen Speicherkontingent enthalten, da es
      größer als 200 MB ist. 

      Dir werden 5€ / Jahr für 5GB Speicherplatz berechnet. 

      Durch das Entfernen von Videobotschaften wirst du im nächsten
      Abonnementzeitraum automatisch herabgestuft, wenn weniger Speicherplatz
      verwendet wird.
  go-back-and-try: >-
    Gehe auf die Seite zurück und gib die PIN ein, um zu erkunden, wie deine
    Empfägner das Video sehen werden.
  good-to-have-you: >-
    Hallo! Schön, dass du hier bist! Du kannst nun deine Videobotschaft
    aufnehmen oder hochladen und unvergessliche Momente teilen.
  heading: Video ansehen!
  info: >-
    Du kannst ein Video mit der Kamera aufnehmen oder ein Video von deinem Gerät
    hochladen. Das Video kann jederzeit geändert werden, sofern die Nachricht
    noch nicht gesendet wurde.
  info-already-sent: >-
    Dein Video wurde bereits versendet. Deshalb kannst du es jetzt nicht mehr
    verändern, aber ansehen.
  leaving-freetier:
    heading: Verlassen des kostenlosen Speicherkontingents
  leaving-freetier-info:
    message: >-
      Nach der Veröffentlichung dieses Videos wirst du das kostenlose Kontingent
      verlassen und dir werden für 5GB Speicherplatz 5€ / Jahr verrechnet. 
      Durch das Entfernen von Videobotschaften wirst du im nächsten
      Abonnementzeitraum automatisch herabgestuft, wenn weniger Speicherplatz
      verwendet wird.
  patience-hint: >-
    Ein bisschen Geduld noch! Bitte vermeide es deinen Browser zu refreshen,
    abhängig von der Größe des Videos kann die Konvertierung einige Minuten
    dauern.
  previewErrorInfo: >-
    <p>Im Moment ist keine Vorschau deines Videos möglich, weil dein Browser
    dieses Format nicht unterstützt.</p> <p><b>Keine Sorge!</b></p> <p>Nach dem
    Upload wandeln wir dein Video in einen Stream um, der auf allen Browsern und
    Endgeräten abgespielt werden kann,  damit du und die Empfänger das
    bestmögliche Benutzererlebnis erfahren.</p>
  select-another: Anderes Video auswählen
  storage-exceeds-info:
    heading: Speicherplatzüberschreitung
    message: >-
      Nach der Veröffentlichung dieses Videos erhälst du ein Upgrade auf die
      nächsten 5 GB Speicherplatz. 

      Wenn du das nicht möchtest, lösche andere Videonachrichten vor dem
      Hochladen oder lade eine kleinere oder kürzere Videonachricht hoch. 

      Dir wird nur der maximal genutzte Speicherplatz innerhalb eines
      Abonnementzeitraums in Rechnung gestellt. 

      Durch das Löschen von Videobotschaften wirst du im nächsten
      Abonnementzeitraum automatisch herabgestuft, wenn weniger Speicherplatz
      verwendet wird.
  successfully-activated: >-
    Du hast das Video erfolgreich aktiviert und die Empfänger können es nun
    mittels Scan des QR-Codes und Eingabe der PIN öffnen.
  upload-new-video: Neues Video hochladen
upload-your-video: Lade dein Video hoch!
validations:
  accept-cookies: Du musst die Cookies akzeptieren, um fortfahren zu können.
  checked: Du musst die Checkbox bestätigen.
  email: Die Email-Addresse ist ungültig.
  maxDate: Das Datum muss vor dem {maxDate} liegen.
  minDate: Das Datum muss nach dem {minDate} liegen.
  minLength: Das Feld muss eine Mindestlänge von {min} haben.
  phone: Die Telefonnummer muss numerisch sein und kann mit einem "+" beginnen.
  required: Dieses Feld ist erforderlich.
  video-must-be-uploaded: Bitte lade zuerst ein Video hoch.
video:
  loading-error: >-
    Beim Laden des Videos ist ein Problem aufgetreten. Bitte versuche, die Seite
    neu zu laden (F5)! Wenn das nicht geholfen hat, wende dich bitte an <a
    hef="link" href="/help">uns</a>.
video-categories:
  BirthBoy: Geburt blau
  BirthGirl: Geburt rosa
  Birthday: Geburtstag
  Christmas: Weihnachten
  Dubai: Dubai
  Easter: Ostern
  FathersDay: Vatertag
  MothersDay: Muttertag
  NewYear: Neujahr
  ThankYou: Danke
  ValentinesDay: Valentinstag
  Wedding: Hochzeit
  business-select-info: >-
    <p>Sie können hier aus den unterschiedlichen Anlässen wählen und die Anzahl
    der gewünschten Karten im Bestellprozess angeben. Für individuelle Angebote
    wenden Sie sich bitte an <a href="mailto:vertrieb&#x0040;imma.video"
    class="link">vertrieb&#x0040;imma.video</a>.</p><br/> <p>Viel Freude beim
    Schenken!</p>
  select-info: Wähle einen Anlass aus und beginne mit dem Verfassen deiner Videobotschaft.
who-should-receive-the-video: Wer soll dein Video erhalten?
zip: PLZ
